<template>
<div class="modal fade " id="RemoteTechnicianUpdate" tabindex="-1" role="dialog" aria-labelledby="RemoteTechnicianUpdate" aria-hidden="true">
    <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title white">Other information's</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">

                <div class="row">

                    <div class="col-12 ">
                        <div class="form-group">
                            <div class="controls">
                                <label>Remote Technician Status</label>
                                <div class="" style="margin-top: .5rem">
                                    <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                                        <div>
                                            <input v-model="remoteTechnicianStatus" type="checkbox" class="custom-control-input" checked id="remoteTechnicianStatusCheckbox">
                                            <label class="custom-control-label mr-1" for="remoteTechnicianStatusCheckbox"></label>
                                        </div>
                                        <span class="font-medium-1">{{remoteTechnicianStatus ? "Active" : "Inactive"}}</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <div class="modal-footer border-0 pt-0">
                <button type="button" class="btn btn-primary" @click="updateRemoteInformation">
                    <i class="bx bx-x d-block d-sm-none"></i>
                    <span class="d-none d-sm-block">Save Changes</span>
                </button>

            </div>
        </div>
    </div>
</div>
</template>

<script>
import {

    mapActions
} from 'vuex';
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";

export default {
    mixins: [ShowToastMessage,Loader],
    name: "RemoteTechnicianUpdate",
    props: ['currentTechnician', 'userId', 'employeeId'],
    components: {

    },
    data() {
        return {
            user_id: '',
            employee_id: '',
            errors: {},
            id: '',

            remoteTechnician: {
                id: '',
                status: '',
            },

            remoteTechnicianStatus: '',

        }
    },
    watch: {
        currentTechnician(technician) {
            this.id = technician.id ? technician.id : '';

            this.remoteTechnician.id = technician.remoteTechnician && technician.remoteTechnician.id ? technician.remoteTechnician.id : '';
            this.remoteTechnician.status = technician.remoteTechnician && technician.remoteTechnician.status ? technician.remoteTechnician.status : '';
            this.remoteTechnicianStatus = technician.remoteTechnician && technician.remoteTechnician.status !== 'Inactive' ? true : false;
        },
        userId(id) {
            this.user_id = id;
        },
        employeeId(id) {
            this.employee_id = id;
        }

    },
    methods: {
        ...mapActions({
            postRemoteTechnician: 'appRemoteTechnicians/postRemoteTechnician',
            putRemoteTechnician: 'appRemoteTechnicians/putRemoteTechnician',

        }),

        async updateRemoteInformation() {

            if (this.remoteTechnician.id) {
                await this.updateRemoteTech();

            } else {

                await this.createRemoteTech();
            }

        },
       async createRemoteTech() {
            this.loader(true);
            const postRemoteTechnicianData = {
                user_id: this.user_id,
                employee_id: this.employee_id,
                technician_id: this.id,
                status: this.remoteTechnicianStatus ? 1 : 0,
            };
            const response = await this.postRemoteTechnician(postRemoteTechnicianData);
            this.loader(false);
            if (response.status === 200 || response.status === 201) {
                document.querySelector('[data-target="#RemoteTechnicianUpdate"]').click();

                this.$emit('getSingleTechnician', this.$route.params.id);

                this.errors = {};
                this.showToastMessage(response.message);
                return true;
            } else {
                this.errors = response.errors;

            }
            if (response.message) {
                this.showToastMessage(response);
            }
            return false;

        },

           async updateRemoteTech() {
            this.loader(true);
            const dataObj = {

                id: this.remoteTechnician.id,
                data: {
                    status: this.remoteTechnicianStatus ? 1 : 0,
                },
            };
            const response = await this.putRemoteTechnician(dataObj);
             this.loader(false);
            if (response.status === 200 || response.status === 201) {
                document.querySelector('[data-target="#RemoteTechnicianUpdate"]').click();

                this.$emit('getSingleTechnician', this.$route.params.id);

                this.errors = {};
                this.showToastMessage(response.message);
                return true;
            } else {
                this.errors = response.errors;

            }
            if (response.message) {
                this.showToastMessage(response);
            }
            return false;

        }
      

    },
    computed: {

    },

}
</script>

<style>

</style>
