<template>
<div class="modal fade " id="TechnicianSalaryInformationUpdate" tabindex="-1" role="dialog" aria-labelledby="TechnicianSalaryInformationUpdate" aria-hidden="true">
    <div class="modal-dialog  modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title white">Update Salary Information</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">

               
                <div class="row ">

                </div>
                <h5>Salary Information</h5>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <label>Salary Type</label>
                            <VueMultiselect v-model="selectedSalaryType" :allow-empty="false" class="" :options="salaryType" :close-on-select="true" placeholder="Select type" label="name" track-by="value" :show-labels="false" />

                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <label>Salary Amount</label>
                            <input v-model="putEmployeeData.salary_amount" type="number" min="0.00" step="0.001" class="form-control " placeholder="Enter salary amount">
                             <div v-if="errors.salary_amount !== undefined && errors.salary_amount.length > 0">
                                    <p v-for="(error,index) in  errors.salary_amount" :key="index" class="text-danger mb-1">
                                        {{ error }}
                                    </p>

                                </div>
                        </div>
                    </div>
                </div>
                <!--
                <h5>Bank Information</h5>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <label>Bank Name</label>
                            <input v-model="postBankAccountData.bank_name" type="text" class="form-control " placeholder="Enter bank name.">

                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <label>Account Name</label>
                            <input v-model="postBankAccountData.account_name" type="text" class="form-control " placeholder="Enter account name.">

                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <label>Account Number</label>
                            <input v-model="postBankAccountData.account_number" type="text" class="form-control " placeholder="Enter account number.">

                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <label>BSB</label>
                            <input v-model="postBankAccountData.bsb" type="text" class="form-control " placeholder="Enter BSB.">

                        </div>
                    </div>
                </div>
                <h5>Other Information</h5>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <label>ABN</label>
                            <input v-model="putEmployeeData.abn" type="text" class="form-control " placeholder="Enter ABN">

                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <div class="controls">
                                <label>Team Lead Status</label>
                                <div class="" style="margin-top: .5rem">
                                    <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                                        <div>
                                            <input v-model="employeeTeamLeadStatus" type="checkbox" class="custom-control-input" checked id="employeeTeamLeadStatusCheckbox">
                                            <label class="custom-control-label mr-1" for="employeeTeamLeadStatusCheckbox"></label>
                                        </div>
                                        <span class="font-medium-1 text-success">{{employeeTeamLeadStatus ? "Active" : "Inactive"}}</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label>Date of Birth</label>
                        <div class="d-flex align-items-center date-picker-wrapper">
                            <div class="d-flex align-items-center">
                                <i class='bx bx-calendar'></i>
                            </div>
                            <div class="w-100 pl-1 ">
                                <div class="w-100 ">
                                    <DatePicker v-model="putUserData.date_of_birth" :update-on-input="true" :masks="{input: ['DD MMMM YYYY']}" :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                                        <template v-slot="{ inputValue, inputEvents }">
                                            <input class="form-control" :value="inputValue" v-on="inputEvents" />
                                        </template>
                                    </DatePicker>
                                </div>
                            </div>
                        </div>

                    </div>
                </div> -->

            </div>

            <div class="modal-footer border-0 pt-0">
                <button type="button" class="btn btn-primary" @click="updateSalaryInformation">
                    <i class="bx bx-x d-block d-sm-none"></i>
                    <span class="d-none d-sm-block">Save Changes</span>
                </button>

            </div>
        </div>
    </div>
</div>
</template>

<script>
import {

    mapActions
} from 'vuex';
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import VueMultiselect from 'vue-multiselect';
export default {
    mixins: [ShowToastMessage],
    name: "TechnicianIdInformationUpdate",
    props: ['employee', 'user'],
    components: {
        VueMultiselect
    },
    data() {
        return {
            errors: {},
            employeeId: '',
            //  'address_id'        => ['exists:addresses,id'],
            // 'abn'               => ['nullable', 'max:20'],
            // 'id_number'         => ['nullable', 'max:20'],
            // 'designation'       => ['max:20'],
            // 'salary_type'       => ['in:0,1'],
            // 'salary_amount'     => ['numeric'],
            salaryType: [{
                    value: 0,
                    name: 'Fixed',
                },
                {
                    value: 1,
                    name: 'Hourly',
                },
            ],
            selectedSalaryType: {
                value: 0,
                name: 'Fixed',
            },
            putEmployeeData: {
                address_id: '',

                salary_type: '',
                salary_amount: ''

            },

        }
    },
    watch: {
        employee(currentEmployee) {

            this.putEmployeeData.address_id = currentEmployee.address.id;
            this.employeeId = currentEmployee.id;
            this.putEmployeeData.salary_amount = currentEmployee.salaryAmount;
            this.salary_type = currentEmployee.salaryType;

            const index = this.salaryType.findIndex((state) => state.name == this.salary_type);
            if (index !== -1) {
                this.selectedSalaryType = this.salaryType[index]
            }

            
        },

    },
    methods: {
        ...mapActions({
            putEmployee: 'appEmployees/putEmployee',

        }),

        async updateSalaryInformation() {
            this.putEmployeeData.salary_type = this.selectedSalaryType.value;
            const dataObj = {
                id: this.employeeId,
                data: this.putEmployeeData,
            };
            const response = await this.putEmployee(dataObj);
            console.log(response);

            if (response.status === 200 || response.status === 201) {
                document.querySelector('[data-target="#TechnicianSalaryInformationUpdate"]').click();

                this.$emit('getSingleTechnician', this.$route.params.id);

                this.errors = {};
                this.showToastMessage(response.message);
            } else {
                this.errors = response.errors;
            }
            if (response.message) {
                this.showToastMessage(response);
            }

        }

    },

}
</script>

<style>

</style>
