<template>
<div class="modal fade " id="TechnicianAbnInformationUpdate" tabindex="-1" role="dialog" aria-labelledby="TechnicianAbnInformationUpdate" aria-hidden="true">
    <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title white">Other Information</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">

                <div class="row">
                    <div class="col-12 ">
                        <div class="form-group">
                            <label>ABN</label>
                            <input v-model="putEmployeeData.abn" type="text" class="form-control " placeholder="Enter ABN">

                        </div>
                    </div>
                    
                </div>
            </div>

            <div class="modal-footer border-0 pt-0">
                <button type="button" class="btn btn-primary" @click="updateOtherInformation">
                    <i class="bx bx-x d-block d-sm-none"></i>
                    <span class="d-none d-sm-block">Save Changes</span>
                </button>

            </div>
        </div>
    </div>
</div>
</template>

<script>
import {

    mapActions
} from 'vuex';
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
// import VueMultiselect from 'vue-multiselect';
// import {
//     DatePicker
// } from 'v-calendar';
export default {
    mixins: [ShowToastMessage],
    name: "TechnicianAbnInformationUpdate",
    props: ['employee', 'user', ],
    components: {

        // DatePicker,

        // VueMultiselect,
    },
    data() {
        return {
            errors: {},
         

            employeeId: '',
        

            putEmployeeData: {
                abn: ''
            },
           

        }
    },
    watch: {
        employee(currentEmployee) {

            this.putEmployeeData.abn = currentEmployee.abn;
            this.employeeId = currentEmployee.id;
        
           

           
        },
       

    },
    methods: {
        ...mapActions({
         
            putEmployee: 'appEmployees/putEmployee',

        }),

        async updateOtherInformation() {

            const dataObj = {
                id: this.employeeId,
                data: this.putEmployeeData,
            };
            const response = await this.putEmployee(dataObj);
            console.log(response);

            if (response.status === 200 || response.status === 201) {
                document.querySelector('[data-target="#TechnicianAbnInformationUpdate"]').click();

                this.$emit('getSingleTechnician', this.$route.params.id);

                this.errors = {};
                this.showToastMessage(response.message);
            } else {
                this.errors = response.errors;
            }
            if (response.message) {
                this.showToastMessage(response);
            }

        },

        

    },

}
</script>

<style>

</style>
