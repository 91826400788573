<template>
<div class="modal fade " id="employeeDetailsUpdateModal" tabindex="-1" role="dialog" aria-labelledby="employeeDetailsUpdateModal" aria-hidden="true">
    <div class="modal-dialog  modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title white">Update User Information</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <h5>Employee Type & Salary Information</h5>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <label>Employee Type</label>
                            <VueMultiselect v-model="selectedEmployeeType" class="" :options="employeeTypes" :close-on-select="true" placeholder="Select the employee type" label="name" track-by="name" :show-labels="false" :allow-empty="false" />
                            <div v-if="errors.type !== undefined && errors.type.length > 0">
                                <p v-for="(error,index) in  errors.type" :key="index" class="text-danger mb-1">
                                    {{ error }}
                                </p>

                            </div>
                        </div>
                    </div>
                    <div v-if="selectedEmployeeType?.name" class="col-12 col-md-6">
                        <div class="form-group">
                            <label>Salary Type *</label>
                            <input readonly type="text" :value="selectedSalaryType.value === 0 ? 'Fixed' : 'Hourly'" class="form-control">
                            <div v-if="errors.salary_type !== undefined && errors.salary_type.length > 0">
                                <p v-for="(error,index) in  errors.salary_type" :key="index" class="text-danger mb-1">
                                    {{ error }}
                                </p>

                            </div>
                        </div>
                    </div>
                    <div v-if="selectedEmployeeType?.name " class="col-12 col-md-6">
                        <div class="form-group">
                            <label>{{ (selectedSalaryType.value === 0 ? 'SALARY AMOUNT' : 'PAYMENT RATE (HOURLY)')+'*' }}</label>
                            <input v-model="salaryAmount" min="0.00" step="0.001" type="number" class="form-control">
                            <div v-if="errors.salary_amount !== undefined && errors.salary_amount.length > 0">
                                <p v-for="(error,index) in  errors.salary_amount" :key="index" class="text-danger mb-1">
                                    {{ error }}
                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <h5>ID Information</h5>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <label>Designation *</label>
                            <input v-model="designation" type="text" class="form-control " placeholder="Enter designation">
                            <div v-if="errors.designation !== undefined && errors.designation.length > 0">
                                <p v-for="(error,index) in  errors.designation" :key="index" class="text-danger mb-1">
                                    {{ error }}
                                </p>

                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <label>ID Number</label>
                            <input v-model="idNumber" type="text" class="form-control " placeholder="Enter a unique ID">
                            <div v-if="errors.id_number !== undefined && errors.id_number.length > 0">
                                <p v-for="(error,index) in  errors.id_number" :key="index" class="text-danger mb-1">
                                    {{ error }}
                                </p>

                            </div>
                        </div>
                    </div>
                    <div v-if="selectedEmployeeType?.name && selectedSalaryType?.value === 1 " class="col-12">
                        <h5>ABN Information</h5>
                        <div class="form-group">
                            <label>ABN Information *</label>
                            <input v-model="abn" type="text" class="form-control " placeholder="Enter ABN">
                            <div v-if="errors.abn !== undefined && errors.abn.length > 0">
                                <p v-for="(error,index) in  errors.abn" :key="index" class="text-danger mb-1">
                                    {{ error }}
                                </p>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="modal-footer border-0 pt-0">
                <button type="button" class="btn btn-primary" @click="updateEmployee">
                    <i class="bx bx-x d-block d-sm-none"></i>
                    <span class="d-none d-sm-block">Save Changes</span>
                </button>

            </div>
        </div>
    </div>
</div>
</template>

<script>
import {

    mapActions
} from 'vuex';
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import VueMultiselect from 'vue-multiselect';
// import {
//     DatePicker
// } from 'v-calendar';
export default {
  mixins: [ShowToastMessage, Loader],
  name: "EmployeeDetailsUpdateModal",
  props: ['existingEmployee'],
  components: {

    // DatePicker,

    VueMultiselect,
  },
  data() {
    return {
      errors: [],
      employeeTypes: [{
        value: 0,
        name: "Permanent Full Time",
      },
        {
          value: 1,
          name: "Permanent Part Time",
        },
        {
          value: 2,
          name: "Contractual",
        },
        {
          value: 3,
          name: "Partner",
        },
      ],
      selectedEmployeeType: {},
      designation: '',
      idNumber: '',
      salaryTypes: [{
        value: 0,
        name: "Fixed",
      },
        {
          value: 1,
          name: "Hourly",
        },
      ],
      selectedSalaryType: {},
      salaryAmount: '',
      abn: '',
      id: '',

    }
  },
  watch: {
    existingEmployee(currentExistingEmployee) {
      this.selectedEmployeeType = this.employeeTypes.find((employee) => employee.name.toLowerCase().trim() === currentExistingEmployee?.type?.toLowerCase()?.trim());
      this.selectedSalaryType = this.salaryTypes.find((salaryType) => salaryType.name.toLowerCase().trim() === currentExistingEmployee?.salary_type?.toLowerCase()?.trim());
      this.abn = currentExistingEmployee?.abn ?? "";
      this.designation = currentExistingEmployee?.designation ?? "";
      this.salaryAmount = currentExistingEmployee && currentExistingEmployee.salary_amount ? (currentExistingEmployee.salary_amount/100).toFixed(2) : 0.00;
      this.idNumber = currentExistingEmployee?.id_number ?? "";
      this.id = currentExistingEmployee?.id ?? "";
    },

    selectedEmployeeType(currentSelectedEmployeeType) {
      if (currentSelectedEmployeeType?.name) {
        this.selectedSalaryType = currentSelectedEmployeeType.value === 0 || currentSelectedEmployeeType.value === 1 ? this.salaryTypes[0] : this.salaryTypes[1];
      }

    }

  },
  methods: {
    ...mapActions({
      putEmployee: 'appEmployees/putEmployee',
    }),

    async updateEmployee() {

      if (this.selectedEmployeeType?.name && (this.salaryAmount+'')&& this.designation) {
      
        const putData = {
            salary_amount:this.salaryAmount*100,
            salary_type:this.selectedSalaryType.value,
            designation:this.designation,
            id_number:this.idNumber,
            type:this.selectedEmployeeType.value
        };
        if (this.selectedSalaryType.value === 1) {
            putData.abn = this.abn
        }
        const dataObj = {
          id: this.id,
          data: putData,
          
        };
        this.loader(true);
        const response = await this.putEmployee(dataObj);
        this.loader(false);
        if (response.status === 200 || response.status === 201) {
          document.querySelector('[data-target="#employeeDetailsUpdateModal"]').click();
          this.$emit('getSingleView', this.$route.params.id);
          this.errors = {};
          this.showToastMessage(response.message);
        } else {
          this.errors = response.errors;
        }
        if (response.message) {
          this.showToastMessage(response);
        }
        return;
      }

      if (!this.selectedEmployeeType?.name) {
        this.showToastMessage({
          type: 'error',
          message: 'Please select employee type'
        });
      }

        if (!(this.salaryAmount+'')) {
          this.showToastMessage({
            type: 'error',
            message: 'Please enter salary amount '
          });
        }
        if (!this.designation) {
          this.showToastMessage({
            type: 'error',
            message: 'Please enter designation '
          });
        }


      

    }

  }
}
</script>

