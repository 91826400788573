<template>
<div class="modal fade " id="TechnicianProductCommissionUpdate" tabindex="-1" role="dialog" aria-labelledby="TechnicianProductCommissionUpdate" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title white">Product Commission</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">

                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <label>Product Commission Type</label>
                            <VueMultiselect v-model="selectedProductCommissionType" class="" :options="productCommissionType" :close-on-select="true" placeholder="Select product commission type" label="name" track-by="value" :show-labels="false" />
                            <!-- <div class="text-danger" v-if="errors.technician.productCommissionType">{{ errors.technician.productCommissionType }}</div> -->
                        </div>
                    </div>
                    <div v-if="selectedProductCommissionType.name !== 'None'" class="col-12 col-md-6">
                        <div class="form-group">
                            <label>Product Commission Rate</label>
                            <input v-model="technicianDetails.product_commission_rate" type="number" min="0.00" step="0.001" class="form-control " placeholder="Enter product commission rate">
                            <div v-if="errors.product_commission_rate !== undefined && errors.product_commission_rate.length > 0">
                                    <p v-for="(error,index) in  errors.product_commission_rate" :key="index" class="text-danger mb-1">
                                        {{ error }}
                                    </p>

                                </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-footer border-0 pt-0">
                <button type="button" class="btn btn-primary" @click="updateOtherInformation">
                    <i class="bx bx-x d-block d-sm-none"></i>
                    <span class="d-none d-sm-block">Save Changes</span>
                </button>

            </div>
        </div>
    </div>
</div>
</template>

<script>
import {

    mapActions
} from 'vuex';
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
// import VueMultiselect from 'vue-multiselect';
// import {
//     DatePicker
// } from 'v-calendar';
export default {
    mixins: [ShowToastMessage],
    name: "TechnicianAbnInformationUpdate",
    props: ['technician'],
    components: {

        // DatePicker,

        // VueMultiselect,
    },
    data() {
        return {
            errors: {},
            selectedProductCommissionType: {
                value: 0,
                name: 'None',
            },
            
            technicianDetails: {
                id: '',
                product_commission_rate: '',
                product_commission_type: '',
            },
            productCommissionType: [{
                    value: 0,
                    name: 'None',
                },
                {
                    value: 1,
                    name: 'Hardware',
                },
                {
                    value: 2,
                    name: 'Software',
                },
                {
                    value: 3,
                    name: 'Both',
                },
            ],

        }
    },
    watch: {
        technician(currentTech) {
            this.technicianDetails.id = currentTech.id;
            this.technicianDetails.product_commission_type = currentTech.product_commission_type;
            this.technicianDetails.product_commission_rate = currentTech.product_commission_rate;

            const index = this.productCommissionType.findIndex((item) => item.name == currentTech.product_commission_type);
            if (index !== -1) {
                this.selectedProductCommissionType = this.productCommissionType[index]
            }

        },

    },
    methods: {
        ...mapActions({

            putTechnician: 'appTechnicians/putTechnician',

        }),

        async updateOtherInformation() {

            const dataObj = {
                id: this.technicianDetails.id,
                data: {
                    product_commission_type:this.selectedProductCommissionType.value ,
                    product_commission_rate: this.selectedProductCommissionType.value === 0 ? 0.00 : this.technicianDetails.product_commission_rate
                },
            };
            const response = await this.putTechnician(dataObj);
            console.log(response);

            if (response.status === 200 || response.status === 201) {
                document.querySelector('[data-target="#TechnicianProductCommissionUpdate"]').click();

                this.$emit('getSingleTechnician', this.$route.params.id);

                this.errors = {};
                this.showToastMessage(response.message);
            } else {
                this.errors = response.errors;
            }
            if (response.message) {
                this.showToastMessage(response);
            }

        },

    },

}
</script>

<style>

</style>
