<template>
  <AppLayout>
    <template v-slot:appContent>
      <div>
        <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Technicians/View</h4>
          <div>
            <router-link :to="{...previousRoute}">
              <span class="glow d-flex align-items-center"><i class='bx bx-undo'></i>&nbsp;Back</span>
            </router-link>
          </div>
        </div>
        <div class="content-body">
          <section class="page-user-profile">
            <div class="row">
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <div class="d-block d-sm-flex mb-2 align-items-center">
                      <div class="mr-2 position-relative">
                        <img :src="employee.avatar" alt="users avatar" class="users-avatar-shadow rounded-circle"
                             height="120" width="120">
                        <div
                            class="position-absolute update-image-avatar d-flex align-items-center justify-content-center"
                            @click="openImageUpdateModal">
                          <i class='bx bxs-camera'></i>
                        </div>
                      </div>
                      <div class="media-body ">
                        <h4 class="media-heading">{{ userFullName }}
                        </h4>
                        <h6 v-if="user.role.name">{{ `${user.role.type} / ${user.role.name}` }}</h6>
                        <div class="d-flex align-items-center" @click="updateTechStatus(status === 'Active' ? 0 : 1)"
                             title="Update status" style="cursor: pointer;">
                          <h6 class="badge" :class="status === 'Active' ? 'badge-light-success' : 'badge-light-danger'">
                            {{ status }} </h6> <span><i class='bx bx-sync text-danger'
                                                        style="font-size: 2rem;cursor: pointer;"></i></span>
                        </div>
                        <div class="col-12 px-0 d-flex">
                          <ul class="nav user-profile-nav justify-content-center justify-content-md-start nav-pills border-bottom-0 mb-0">
                            <li class="nav-item mb-0">
                              <a class="nav-link d-flex px-1" :class="[tabStep === 1 ? 'active' : '']"
                                 @click="tabJump(1)" href="#" aria-controls="feed" role="tab" aria-selected="true"><i
                                  class='bx bx-user-check'></i><span
                                  class="d-none d-md-block">Employee Information</span></a>
                            </li>
                            <li class="nav-item mb-0">
                              <a class="nav-link d-flex px-1" :class="[tabStep === 2 ? 'active' : '']"
                                 @click="tabJump(2)" href="#" aria-controls="activity" role="tab" aria-selected="false"><i
                                  class="bx bx-user"></i><span class="d-none d-md-block">Technician Settings</span></a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-3 ">
                    <div class="card ">
                      <div class="card-body">
                        <h5 class="mb-1">User Information <span style="cursor: pointer;" data-toggle="modal"
                                                                data-target="#TechnicianUserUpdateModal" title="Edit"
                                                                class="single-edit-btn text-primary"><i
                            class='bx bx-edit-alt'></i></span></h5>
                        <ul class="list-unstyled">
                          <li class="mb-1">
                            <span class="text-bold-700 mb-1 text-capitalize" style="text-transform: capitalize;">First Name: </span>
                            <span>{{ user.firstName }}</span>
                          </li>
                          <li class="mb-1">
                            <span class="text-bold-700 mb-1 text-capitalize" style="text-transform: capitalize;">Last Name: </span>
                            <span>{{ user.lastName }}</span>
                          </li>
                          <li class="mb-1">
                            <span class="text-bold-700 mb-1">Phone Number: </span>
                            <span>{{ user.phoneNumber }}</span>
                          </li>
                          <li class="mb-1">
                            <span class="text-bold-700 mb-1">Email: </span>
                            <span>{{ user.email }}</span>
                          </li>
                          <li class="mb-1">
                            <span class="text-bold-700 mb-1">Date Of Birth: </span>
                            <span>{{ user.dateOfBirth ? user.dateOfBirth : 'No Data Found!' }}</span>
                          </li>
                          <li class="mb-1">
                            <span class="text-bold-700 mb-1">Created at: </span>
                            <span>{{ createdAt }}</span>
                          </li>
                          <li class="mb-1">
                            <span class="text-bold-700 mb-1">Last Updated at: </span>
                            <span>{{ updatedAt }}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-9 mb-4">
                    <div class="tab-content  ">
                      <div v-if="tabStep === 1" aria-labelledby="profile-tab">
                        <div class="card">
                      <div class="card-body">
                        <div>
                          <div class="d-flex align-items-center mb-1">
                            <div>
                              <h5 class="text-bold-400 text-capitalize" style="text-transform: capitalize;">
                                {{ "Address Information" }}
                              </h5>
                            </div>
                            <div class="ml-1" style="cursor: pointer;">
                              <button :disabled="!can('technician-update')"
                                      :title="can('technician-update') ? 'Update' : 'Update Forbidden'"
                                      data-toggle="modal" data-target="#addressUpdateModal"
                                      class="btn single-edit-btn text-primary">
                                <i class='bx bx-edit-alt'></i>
                              </button>
                            </div>
                          </div>

                          <div v-if="employee.address.street" class="row">
                            <div class="col-sm-12 col-12">
                              <h6>
                                <small class="text-muted">
                                  {{ 'CURRENT ADDRESS:' }}
                                </small>
                              </h6>
                              <p>{{ userFullAddress }}</p>
                            </div>
                          </div>
                          <div v-else>
                            <p>No Address Assigned</p>
                          </div>

                         
                        </div>
                      </div>
                    </div>
                        <div class="card ">
                          <div class="card-body ">
                            <div class="">
                              <h5 class="pb-1">Employee Information<span style="cursor: pointer;"
                                                                         @click="openEmployeeUpdateModal()" title="Edit"
                                                                         class="single-edit-btn text-primary"><i
                                  class='bx bx-edit-alt'></i></span></h5>
                              <div class="row">
                                <div class="col-sm-6 col-12">
                                  <h6 class="text-muted text-bold-700">TYPE : <span
                                      class="text-bold-400"> {{ employee.type ? employee.type : 'No Data Found' }}</span>
                                  </h6>
                                </div>
                                <div class="col-sm-6 col-12">
                                  <h6 class="text-muted text-bold-700">ABN : <span
                                      class="text-bold-400"> {{ employee.abn ? employee.abn : 'No Data Found' }}</span>
                                  </h6>
                                </div>
                              </div>
                              <div class="row mt-md-1">
                                <div class="col-sm-6 col-12">
                                  <h6 class="text-muted text-bold-700">DESIGNATION : <span
                                      class="text-bold-400"> {{ employee.designation ? employee.designation : 'No Data Found' }}</span>
                                  </h6>
                                </div>
                                <div class="col-sm-6 col-12 mt-1 mt-md-0">
                                  <h6 class="text-muted text-bold-700">ID NUMBER : <span
                                      class="text-bold-400"> {{ employee.idNumber ? employee.idNumber : 'No Data Found' }}</span>
                                  </h6>

                                </div>
                              </div>

                              <div class="row mt-md-1">
                                <div class="col-sm-6 col-12">
                                  <h6 class="text-muted text-bold-700">SALARY TYPE : <span
                                      class="text-bold-400"> {{ employee.salaryType ? employee.salaryType : 'No Data Found' }}</span>
                                  </h6>
                                </div>
                                <div class="col-sm-6 col-12 mt-1 mt-md-0">
                                  <h6 class="text-muted text-bold-700">SALARY AMOUNT : <span
                                      class="text-bold-400"> {{ employee.salaryAmount ? (employee.salaryAmount/100).toFixed(2) : 'No Data Found' }}</span>
                                  </h6>
                                  <!-- <h5><small class="text-muted">SALARY AMOUNT: </small></h5>
                                      <p>{{ employee.salaryAmount ?? 0 /100}}</p> -->
                                </div>
                              </div>

                            </div>
                            <hr>
                            <div>
                              <h5 class="card-title">Bank Information <span style="cursor: pointer;" data-toggle="modal"
                                                                            data-target="#TechnicianBankAccountUpdate"
                                                                            title="Edit"
                                                                            class="single-edit-btn text-primary"><i
                                  class='bx bx-edit-alt'></i></span></h5>
                              <div v-if="user.bankAccount.id" class="row">
                                <div class="col-sm-6 col-12">
                                  <h6><small class="text-muted">BANK NAME: </small></h6>
                                  <p>{{ user.bankAccount.bankName }}</p>
                                </div>
                                <div class="col-sm-6 col-12 mt-1 mt-md-0">
                                  <h6><small class="text-muted">ACCOUNT NAME: </small></h6>
                                  <p>{{ user.bankAccount.accountName }}</p>
                                </div>
                                <div class="col-sm-6 col-12 mt-1 mt-md-0">
                                  <h6><small class="text-muted">ACCOUNT NUMBER: </small></h6>
                                  <p>{{ user.bankAccount.accountNumber }}</p>
                                </div>
                                <div class="col-sm-6 col-12 mt-1 mt-md-0">
                                  <h6><small class="text-muted">BSB: </small></h6>
                                  <p>{{ user.bankAccount.bsb }}</p>
                                </div>
                              </div>
                              <div v-else>
                                <p>Bank Information Not Provided</p>
                              </div>
                            </div>
                            <hr class="my-2  " style="'">
                            <div>
                              <h5 class="card-title" v-if="employee.teamLead.id">Other Information </h5>
                              <div class="row">
                                <div v-if="employee.teamLead.id" class="col-sm-6 col-12 mt-1 mt-md-0">
                                  <h6><small class="text-muted">TEAM LEAD STATUS: </small> <span
                                      style="cursor: pointer;" data-toggle="modal"
                                      data-target="#TechnicianEmployeeTeamLeadInformationUpdate" title="Edit"
                                      class="single-edit-btn text-primary"><i class='bx bx-edit-alt'></i></span></h6>
                                  <p class="badge mr-1"
                                     :class="employee.teamLead.status === 'Active' ? 'badge-light-success' : 'badge-light-danger'"
                                     style="padding: 4px;">{{ employee.teamLead.status }}</p>
                                </div>
                                <div v-else>
                                  <p>No team lead assigned <span style="cursor: pointer;" data-toggle="modal"
                                                                 data-target="#TechnicianEmployeeTeamLeadInformationUpdate"
                                                                 title="Edit" class="single-edit-btn text-primary"><i
                                      class='bx bx-edit-alt'></i></span></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="tabStep === 2" aria-labelledby="profile-tab">
                        <div class="card">
                          <div class="card-body">
                            <div>
                              <h5 class="card-title">Product Commission <span style="cursor: pointer;"
                                                                              data-toggle="modal"
                                                                              data-target="#TechnicianProductCommissionUpdate"
                                                                              title="Edit"
                                                                              class="single-edit-btn text-primary"><i
                                  class='bx bx-edit-alt'></i></span></h5>
                              <div class="row">
                                <div class="col-sm-6 col-12">
                                  <h6><small class="text-muted pb-0">PRODUCT COMMISSION TYPE: </small></h6>
                                  <p>{{ productCommissionType }}</p>
                                </div>
                                <div v-if="productCommissionType !== 'None'" class="col-sm-6 col-12">
                                  <h6><small class="text-muted pb-0">PRODUCT COMMISSION RATE: </small></h6>
                                  <p>{{ productCommissionRate }}</p>
                                </div>
                              </div>
                            </div>
                            <hr>
                            <div>
                              <h5 class="card-title">Others Information <span style="cursor: pointer;"
                                                                              data-toggle="modal"
                                                                              data-target="#TechnicianOtherInformationUpdate"
                                                                              title="Edit"
                                                                              class="single-edit-btn text-primary"><i
                                  class='bx bx-edit-alt'></i></span></h5>
                              <div class="row">
                                <div class="col-sm-6 col-12">
                                  <h6><small class="text-muted">TIME INTERVAL (HOUR):</small></h6>
                                  <p>{{ intervalTime }}</p>
                                </div>
                                <div class="col-sm-6 col-12 mt-1 mt-md-0">
                                  <h6><small class="text-muted">TEAM LEAD: </small></h6>
                                  <p v-if="teamLead.id && teamLead.user.id">{{ teamLeadFullName }}</p>
                                  <p v-else>No Team Lead Assigned</p>
                                </div>

                                <div v-if="onSiteTechnician.id+''" class="col-12 col-sm-6 mt-1">
                                  <h6><small class="text-muted">ON-SITE TECHNICIAN STATUS: </small> <span
                                      style="cursor: pointer;" data-toggle="modal" data-target="#OnSiteTechnicianUpdate"
                                      title="Edit" class="single-edit-btn text-primary"><i
                                      class='bx bx-edit-alt'></i></span></h6>
                                  <p class="badge mr-1"
                                     :class="onSiteTechnician.status === 'Active' ? 'badge-light-success' : 'badge-light-danger'"
                                     style="padding: 4px;">{{ onSiteTechnician.status }}</p>
                                </div>
                                <div v-else class="col-12 col-sm-6 mt-1">
                                  <h6><small class="text-muted">No ON-SITE TECHNICIAN AVAILABLE </small> <span
                                      style="cursor: pointer;" data-toggle="modal" data-target="#OnSiteTechnicianUpdate"
                                      title="Edit" class="single-edit-btn text-primary"><i
                                      class='bx bx-edit-alt'></i></span></h6>

                                </div>
                                <div v-if="remoteTechnician.id+''" class="col-12 col-sm-6 mt-1">
                                  <h6><small class="text-muted">REMOTE TECHNICIAN STATUS: </small> <span
                                      style="cursor: pointer;" data-toggle="modal" data-target="#RemoteTechnicianUpdate"
                                      title="Edit" class="single-edit-btn text-primary"><i
                                      class='bx bx-edit-alt'></i></span></h6>
                                  <p class="badge mr-1"
                                     :class="remoteTechnician.status === 'Active' ? 'badge-light-success' : 'badge-light-danger'"
                                     style="padding: 4px;">{{ remoteTechnician.status }}</p>
                                </div>
                                <div v-else class="col-12 col-sm-6 mt-1">
                                  <h6><small class="text-muted">NO REMOTE TECHNICIAN AVAILABLE </small> <span
                                      style="cursor: pointer;" data-toggle="modal" data-target="#RemoteTechnicianUpdate"
                                      title="Edit" class="single-edit-btn text-primary"><i
                                      class='bx bx-edit-alt'></i></span></h6>

                                </div>
                                <div class="col-12 col-sm-6 mt-1">
                                  <h6><small class="text-muted">TECHNICIAN STATUS: </small></h6>
                                  <p class="badge mr-1"
                                     :class="status === 'Active' ? 'badge-light-success' : 'badge-light-danger'"
                                     style="padding: 4px;">{{ status }}</p>
                                </div>
                                <div class="col-12 mt-1 ">
                                  <h6><small class="text-muted">SKILLS: </small></h6>
                                  <p v-html="skills"></p>
                                </div>
                              </div>
                            </div>
                            <hr>
                            <div>
                              <router-link :to="{name:'appTechnicianScheduleEdit', params: {id: id}}">
                                <button type="button" class="btn btn-primary glow  mb-1"><span><i
                                    class='bx bx-timer'></i></span>
                                  <span class="align-middle ml-25">Availability</span></button>
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <TechnicianIdInformationUpdate v-on:getSingleTechnician="getSingleTechnician($event)"
                                         :employee="{...employee}" :user="{...user}"/>
          <TechnicianSalaryInformationUpdate v-on:getSingleTechnician="getSingleTechnician($event)"
                                             :employee="{...employee}" :user="{...user}"/>
          <TechnicianBankAccountUpdate v-on:getSingleTechnician="getSingleTechnician($event)" :employee="{...employee}"
                                       :user="{...user}"/>
          <TechnicianAbnInformationUpdate v-on:getSingleTechnician="getSingleTechnician($event)"
                                          :employee="{...employee}" :user="{...user}"/>
          <TechnicianEmployeeTeamLeadInformationUpdate v-on:getSingleTechnician="getSingleTechnician($event)"
                                                       :employee="{...employee}" :user="{...user}"/>
          <TechnicianProductCommissionUpdate v-on:getSingleTechnician="getSingleTechnician($event)"
                                             :technician="{...technician}"/>
          <TechnicianOtherInformationUpdate v-on:getSingleTechnician="getSingleTechnician($event)" :userId="user.id"
                                            :employeeId="employee.id" :currentTechnician="{...technician}"/>
          <OnSiteTechnicianUpdate v-on:getSingleTechnician="getSingleTechnician($event)" :userId="user.id"
                                  :employeeId="employee.id" :currentTechnician="{...technician}"/>
          <RemoteTechnicianUpdate v-on:getSingleTechnician="getSingleTechnician($event)" :userId="user.id"
                                  :employeeId="employee.id" :currentTechnician="{...technician}"/>
          <TechnicianUserUpdateModal v-on:getSingleTechnician="getSingleTechnician($event)"
                                     :addressId="employee.address.id" :user="{...user}"/>

          <div class="" data-toggle="modal" data-target="#updateAvatarModal"></div>

          <div class="" data-toggle="modal" data-target="#employeeDetailsUpdateModal"></div>
          <EmployeeDetailsUpdateModal v-on:getSingleView="getSingleTechnician($event)"
                                      :existing-employee="{...technician?.employee}"/>
          <div class="" data-toggle="modal" data-target="#updateAvatarModal"></div>
          <ImageUpdateModal v-on:getSingleView="uploadImage($event)" :previousImage="employee.avatar"/>

          <AddressUpdateModal
              modal-name="addressUpdateModal"
              address-update-for-model-name="employee"
              :model-id="id"
              :user-id="user.id"
              :selected-address-data="selectedAddressData"
              :business-status="false"
              @updateModalAddressData="updateModalAddressData"/>
        </div>
      </div>
    </template>
  </AppLayout>
</template>

<script>
// component
import AppLayout from '@/layouts/backEnd/AppLayout.vue'
import TechnicianIdInformationUpdate
  from '@/views/backEnd/users/technicians/includes/TechnicianIdInformationUpdate.vue';
import TechnicianSalaryInformationUpdate
  from '@/views/backEnd/users/technicians/includes/TechnicianSalaryInformationUpdate.vue';
import TechnicianBankAccountUpdate from '@/views/backEnd/users/technicians/includes/TechnicianBankAccountUpdate.vue';
import TechnicianAbnInformationUpdate
  from '@/views/backEnd/users/technicians/includes/TechnicianAbnInformationUpdate.vue';
import TechnicianEmployeeTeamLeadInformationUpdate
  from '@/views/backEnd/users/technicians/includes/TechnicianEmployeeTeamLeadInformationUpdate.vue';
import TechnicianProductCommissionUpdate
  from '@/views/backEnd/users/technicians/includes/TechnicianProductCommissionUpdate.vue';
import TechnicianOtherInformationUpdate
  from '@/views/backEnd/users/technicians/includes/TechnicianOtherInformationUpdate.vue';
import OnSiteTechnicianUpdate from '@/views/backEnd/users/technicians/includes/OnSiteTechnicianUpdate.vue';
import RemoteTechnicianUpdate from '@/views/backEnd/users/technicians/includes/RemoteTechnicianUpdate.vue';
import TechnicianUserUpdateModal from '@/views/backEnd/users/technicians/includes/TechnicianUserUpdateModal.vue';


import AddressUpdateModal from "@/components/backEnd/modal/AddressUpdateModal";
import ImageUpdateModal from "@/components/backEnd/modal/ImageUpdateModal";
// mixin
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";

// core package
import {
  mapActions,
  mapGetters
} from "vuex";
import EmployeeDetailsUpdateModal from "@/views/backEnd/users/technicians/includes/EmployeeDetailsUpdateModal";

export default {
  name: "TechnicianView",

  mixins: [ShowToastMessage, Loader,Authorization],

  components: {
    EmployeeDetailsUpdateModal,
    AppLayout,
    TechnicianIdInformationUpdate,
    TechnicianSalaryInformationUpdate,
    TechnicianBankAccountUpdate,
    TechnicianAbnInformationUpdate,
    TechnicianEmployeeTeamLeadInformationUpdate,
    TechnicianProductCommissionUpdate,
    TechnicianOtherInformationUpdate,
    OnSiteTechnicianUpdate,
    RemoteTechnicianUpdate,
    TechnicianUserUpdateModal,
    ImageUpdateModal,
    AddressUpdateModal

  },

  data() {
    return {
      tabStep: 1,

      id: '',
      productCommissionType: '',
      productCommissionRate: '',
      intervalTime: '',
      skills: '',
      status: '',
      createdAt: '',
      updatedAt: '',

      user: {
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        dateOfBirth: '',
        avatar: '/app-assets/defaults/images/users/avatar.svg',
        role: {
          id: '',
          type: '',
          name: '',
          description: '',
        },
        bankAccount: {
          id: '',
          bankName: '',
          accountName: '',
          accountNumber: '',
          bsb: '',
        },
      },

      employee: {
        id: '',
        abn: '',
        type: '',
        idNumber: '',
        designation: '',
        salaryType: '',
        salaryAmount: '',
        avatar: '',
        address: {
          id: '',
          street: '',
          suburb: '',
          state: '',
          postCode: '',
          country: '',
        },
        teamLead: {
          id: '',
          status: '',
        },

      },
      teamLead: {
        id: '',
        status: '',
        user: {
          id: '',
          firstName: '',
          lastName: '',
        }
      },
      onSiteTechnician: {
        id: '',
        status: '',
      },
      remoteTechnician: {
        id: '',
        status: '',
      },

      getTechnicianParams: {
        with_relation: [
          'user',
          'user.role',
          'user.bankAccount',
          'user.media',
          'employee',
          'employee.address',
          'employee.teamLead',
          'teamLead',
          'teamLead.user',
          'onSiteTechnician',
          'remoteTechnician',
          'employee.media'

        ],
      },
      getTeamLeadsParams: {
        status: 1,
        with_relation: ['user'],

      }
    };
  },
  computed: {
    ...mapGetters({
      technician: "appTechnicians/technician",
      previousRoute: "previousRoute",

    }),

    userFullName() {
      return `${this.user.firstName} ${this.user.lastName}`;
    },

    teamLeadFullName() {
      return `${this.teamLead.user.firstName} ${this.teamLead.user.lastName}`;
    },

    employeeFullAddress() {
      let state = this.employee.address.state.toUpperCase();
      return `${this.employee.address.street}, ${this.employee.address.suburb}, ${state},  ${this.employee.address.postCode}, ${this.employee.address.country}`;
    },
    userFullAddress() {
      // let businessName = this.address.business.name;
      let addressStreet = this.employee.address.street;
      let addressSuburb = this.employee.address.suburb;
      let addressState = this.employee.address.state.toUpperCase();
      let addressPostCode = this.employee.address.postCode;
      let addressCountry = this.employee.address.country;
      return this.type === 'Business' ?
          `(${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry})` :
          `${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry}`;
    },
    selectedAddressData() {
      return {
        value: this.employee.address.id,
        name: this.userFullAddress,
      };
    },
  },
  methods: {
    ...mapActions({
      resetTechnician: "appTechnicians/resetTechnician",
      getTechnician: "appTechnicians/getTechnician",
      putTechnician: "appTechnicians/putTechnician",
      getTeamLeads: 'appTeamLeads/getTeamLeads',
      putAvatar: 'appEmployees/putAvatar',
    }),
    async updateModalAddressData(updateModalAddressData) {
      this.employee.address.id = updateModalAddressData.addressData.id;
      this.employee.address.street = updateModalAddressData.addressData.street;
      this.employee.address.suburb = updateModalAddressData.addressData.suburb;
      this.employee.address.state = updateModalAddressData.addressData.state;
      this.employee.address.postCode = updateModalAddressData.addressData.postCode;
      this.employee.address.country = updateModalAddressData.addressData.country;

      // if (this.type === 'Business') {
      //   this.address.business.id = updateModalAddressData.addressData.business.id;
      //   this.address.business.name = updateModalAddressData.addressData.business.name;
      // }
    },
    async updateTechStatus(status) {
      let dataObj = {
        id: this.id,
        data: {
          status: status,
        },
      };
      this.putTechnician(dataObj).then(async (response) => {
        if (response.status === 200) {
          this.status = this.technician.status;
          let toastObj = {
            message: 'Technician Info Updated',
            type: 'success'
          };
          this.showToastMessage(toastObj);
          return;
        }
        this.showToastMessage(response);
      });
    },

    openImageUpdateModal() {
      document.querySelector('[data-target="#updateAvatarModal"]').click();
    },
    async uploadImage(imageBase64) {

      const payLoad = {
        id: this.employee.id,
        data: {
          avatar: imageBase64
        }
      }
      const response = await this.putAvatar(payLoad);
      if (response.status === 201 || response.status === 200) {
        this.loader(false);
        document.querySelector('[data-target="#updateAvatarModal"]').click();
        await this.getSingleTechnician(this.$route.params.id)
        // this.$emit('getSingleView', this.$route.params.id);

      }
      if (response && response.message) {
        this.showToastMessage(response);
      }
    },
    openEmployeeUpdateModal() {
      document.querySelector('[data-target="#employeeDetailsUpdateModal"]').click();
    },

    async tabJump(tabToJump) {
      this.tabStep = tabToJump;
    },

    async getSingleTechnician(id) {
      this.loader(true);
      let paramObj = {
        id: id,
        params: this.getTechnicianParams,
      };
      await this.getTechnician(paramObj).then(async (response) => {
        this.loader(false);
        if (response.status === 200) {
          this.id = this.technician.id ? this.technician.id : '';
          this.productCommissionType = this.technician.product_commission_type ? this.technician.product_commission_type : '';
          this.productCommissionRate = this.technician.product_commission_rate ? this.technician.product_commission_rate : '';
          this.intervalTime = this.technician.interval_time ? this.technician.interval_time : '';
          this.skills = this.technician.skills ? this.technician.skills : '';
          this.status = this.technician.status ? this.technician.status : '';
          this.createdAt = this.technician.created_at ? this.technician.created_at : '';
          this.updatedAt = this.technician.updated_at ? this.technician.updated_at : '';

          this.user.id = this.technician.user && this.technician.user.id ? this.technician.user.id : '';
          this.user.firstName = this.technician.user && this.technician.user.first_name ? this.technician.user.first_name : '';
          this.user.lastName = this.technician.user && this.technician.user.last_name ? this.technician.user.last_name : '';
          this.user.email = this.technician.user && this.technician.user.email ? this.technician.user.email : '';
          this.user.phoneNumber = this.technician.user && this.technician.user.phone_number ? this.technician.user.phone_number : '';
          this.user.dateOfBirth = this.technician.user && this.technician.user.date_of_birth ? this.technician.user.date_of_birth : '';
          this.user.avatar = this.technician.user && this.technician.user.avatar ? this.technician.user.avatar : '';

          this.user.role.id = this.technician.user && this.technician.user.role && this.technician.user.role.id ? this.technician.user.role.id : '';
          this.user.role.type = this.technician.user && this.technician.user.role && this.technician.user.role.type ? this.technician.user.role.type : '';
          this.user.role.name = this.technician.user && this.technician.user.role && this.technician.user.role.name ? this.technician.user.role.name : '';
          this.user.role.description = this.technician.user && this.technician.user.role && this.technician.user.role.description ? this.technician.user.role.description : '';

          this.user.bankAccount.id = this.technician.user && this.technician.user.bankAccount && this.technician.user.bankAccount.id ? this.technician.user.bankAccount.id : '';
          this.user.bankAccount.bankName = this.technician.user && this.technician.user.bankAccount && this.technician.user.bankAccount.bank_name ? this.technician.user.bankAccount.bank_name : '';
          this.user.bankAccount.accountNumber = this.technician.user && this.technician.user.bankAccount && this.technician.user.bankAccount.account_number ? this.technician.user.bankAccount.account_number : '';
          this.user.bankAccount.bsb = this.technician.user && this.technician.user.bankAccount && this.technician.user.bankAccount.bsb ? this.technician.user.bankAccount.bsb : '';
          this.user.bankAccount.accountName = this.technician.user && this.technician.user.bankAccount && this.technician.user.bankAccount.account_name ? this.technician.user.bankAccount.account_name : '';

          this.employee.id = this.technician.employee && this.technician.employee.id ? this.technician.employee.id : '';
          this.employee.abn = this.technician.employee && this.technician.employee.abn ? this.technician.employee.abn : '';
          this.employee.idNumber = this.technician.employee && this.technician.employee.id_number ? this.technician.employee.id_number : '';
          this.employee.designation = this.technician.employee && this.technician.employee.designation ? this.technician.employee.designation : '';
          this.employee.salaryType = this.technician.employee && this.technician.employee.salary_type ? this.technician.employee.salary_type : '';
          this.employee.salaryAmount = this.technician.employee && this.technician.employee.salary_amount ? this.technician.employee.salary_amount : '';
          this.employee.type = this.technician.employee && this.technician.employee.type ? this.technician.employee.type : '';

          this.employee.avatar = this.technician.employee && this.technician.employee.avatar ? this.technician.employee.avatar : '';
          this.employee.address.id = this.technician.employee && this.technician.employee.address && this.technician.employee.address.id ? this.technician.employee.address.id : '';
          this.employee.address.street = this.technician.employee && this.technician.employee.address && this.technician.employee.address.street ? this.technician.employee.address.street : '';
          this.employee.address.suburb = this.technician.employee && this.technician.employee.address && this.technician.employee.address.suburb ? this.technician.employee.address.suburb : '';
          this.employee.address.state = this.technician.employee && this.technician.employee.address && this.technician.employee.address.state ? this.technician.employee.address.state : '';
          this.employee.address.postcode = this.technician.employee && this.technician.employee.address && this.technician.employee.address.post_code ? this.technician.employee.address.post_code : '';
          this.employee.address.country = this.technician.employee && this.technician.employee.address && this.technician.employee.address.country ? this.technician.employee.address.country : '';

          this.employee.teamLead.id = this.technician.employee && this.technician.employee.teamLead && this.technician.employee.teamLead.id ? this.technician.employee.teamLead.id : '';
          this.employee.teamLead.status = this.technician.employee && this.technician.employee.teamLead && this.technician.employee.teamLead.status ? this.technician.employee.teamLead.status : '';

          this.teamLead.id = this.technician.team_lead && this.technician.team_lead.id ? this.technician.team_lead.id : '';
          this.teamLead.status = this.technician.team_lead && this.technician.team_lead.status ? this.technician.team_lead.status : '';

          this.teamLead.user.id = this.technician.team_lead && this.technician.team_lead.user && this.technician.team_lead.user.id ? this.technician.team_lead.user.id : '';
          this.teamLead.user.firstName = this.technician.team_lead && this.technician.team_lead.user && this.technician.team_lead.user.first_name ? this.technician.team_lead.user.first_name : '';
          this.teamLead.user.lastName = this.technician.team_lead && this.technician.team_lead.user && this.technician.team_lead.user.last_name ? this.technician.team_lead.user.last_name : '';

          this.onSiteTechnician.id = this.technician.onSiteTechnician && this.technician.onSiteTechnician.id ? this.technician.onSiteTechnician.id : '';
          this.onSiteTechnician.status = this.technician.onSiteTechnician && this.technician.onSiteTechnician.status ? this.technician.onSiteTechnician.status : '';

          this.remoteTechnician.id = this.technician.remoteTechnician && this.technician.remoteTechnician.id ? this.technician.remoteTechnician.id : '';
          this.remoteTechnician.status = this.technician.remoteTechnician && this.technician.remoteTechnician.status ? this.technician.remoteTechnician.status : '';
        }

        if (response && response.message && response.status !== 200) {
          this.showToastMessage(response);
        }
      });
    },
  },
  async mounted() {
    this.loader(true);
    const routeParams = await this.$route.params;
    await this.getSingleTechnician(routeParams.id);
    await this.getTeamLeads(this.getTeamLeadsParams);
  },
  async beforeUnmount() {
    await this.resetTechnician();
  },
}
</script>

<style scoped>
p {
  margin: 0;
  padding: 0;
  font-weight: 600;
}

.update-image-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #5a8dee;
  border: 2px solid white;
  top: 79%;
  right: 10px;
  z-index: 10;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.update-image-avatar:hover {
  background-color: #4173ce;
}

.update-image-avatar > i {
  font-size: 20px;
  z-index: 10;
  color: white;
}

@media only screen and (max-width: 576px) {
  .update-image-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #5a8dee;
    border: 2px solid white;
    top: 64%;
    left: 92px;
    right: 0;
    z-index: 10;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
}
</style>
