<template>
<div class="modal fade " id="TechnicianOtherInformationUpdate" tabindex="-1" role="dialog" aria-labelledby="TechnicianOtherInformationUpdate" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title white">Other information's</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">

                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <label>Time Interval (Hour)</label>
                            <input v-model="interval_time_Hour" type="number" class="form-control " placeholder="Enter interval time.">
                            <!-- <div class="text-danger" v-if="errors.technician.intervalTime">{{ errors.technician.intervalTime }}</div> -->
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <label>Time Interval (Min)</label>
                            <input v-model="interval_time_Minute" type="number" class="form-control " placeholder="Enter interval time.">
                            <!-- <div class="text-danger" v-if="errors.technician.intervalTime">{{ errors.technician.intervalTime }}</div> -->
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <label>Team Lead</label>
                            <VueMultiselect :allow-empty="false" v-model="selectedTeamLead" class="" :options="teamLeads" :close-on-select="true" placeholder="Select a team lead" label="name" track-by="value" :show-labels="false" />
                            <!-- <div class="text-danger" v-if="errors.technician.teamLeadId">{{ errors.technician.teamLeadId }}</div> -->
                        </div>
                    </div>
                    <div class="col-12 col-md-12">
                        <div class="form-group">
                            <label>Skills</label>
                            <QuillEditor ref="skillRef" v-model:content="skills" contentType="html" toolbar="full" theme="snow" />

                        </div>
                    </div>

                    <div class="col-12 col-md-3">
                        <div class="form-group">
                            <div class="controls">
                                <label>Technician Status</label>
                                <div class="" style="margin-top: .5rem">
                                    <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                                        <div>
                                            <input v-model="technicianStatus" type="checkbox" class="custom-control-input" checked id="technicianStatusStatusCheckbox">
                                            <label class="custom-control-label mr-1" for="technicianStatusStatusCheckbox"></label>
                                        </div>
                                        <span class="font-medium-1">{{technicianStatus ? "Active" : "Inactive"}}</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <div class="modal-footer border-0 pt-0">
                <button type="button" class="btn btn-primary" @click="updateOtherInformation">
                    <i class="bx bx-x d-block d-sm-none"></i>
                    <span class="d-none d-sm-block">Save Changes</span>
                </button>

            </div>
        </div>
    </div>
</div>
</template>

<script>
import {

    mapActions
} from 'vuex';
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import {
    QuillEditor
} from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
    mixins: [ShowToastMessage,Loader],
    name: "TechnicianOtherInformationUpdate",
    props: ['currentTechnician', 'userId', 'employeeId'],
    components: {
        QuillEditor,

    },
    data() {
        return {
            user_id: '',
            employee_id: '',
            errors: {},
            id: '',
            interval_time_Hour: '',
            interval_time_Minute: '',
            selectedTeamLead: {
                value: '',
                name: 'No One'
            },
            onSiteTechnician: {
                id: '',
                status: '',
            },
            remoteTechnician: {
                id: '',
                status: '',
            },
            intervalTime: '',
            skills: '',

            teamLead: {
                id: '',
                status: '',
                user: {
                    id: '',
                    firstName: '',
                    lastName: '',
                }
            },

            onSiteTechnicianStatus: '',

            remoteTechnicianStatus: '',

            technicianStatus: '',

        }
    },
    watch: {
        currentTechnician(technician) {
            this.id = technician.id ? technician.id : '';

            this.onSiteTechnician.id = technician.on_site_technician && technician.on_site_technician.id ? technician.on_site_technician.id : '';
            this.onSiteTechnician.status = technician.on_site_technician && technician.on_site_technician.status ? technician.on_site_technician.status : '';
            this.onSiteTechnicianStatus = technician.on_site_technician && technician.on_site_technician.status !== 'Inactive' ? true : false;

            this.remoteTechnician.id = technician.remote_technician && technician.remote_technician.id ? technician.remote_technician.id : '';
            this.remoteTechnician.status = technician.remote_technician && technician.remote_technician.status ? technician.remote_technician.status : '';
            this.remoteTechnicianStatus = technician.remote_technician && technician.remote_technician.status !== 'Inactive' ? true : false;

            this.intervalTime = technician.interval_time ? technician.interval_time : '';

            // let hour = "";
            // let min = "";
            // let minStatus = false;
            // if (this.intervalTime) {
            //     for (let i = 0; i < this.intervalTime.length; i++) {
            //         if (this.intervalTime[i] === ':') {
            //             minStatus = true;
            //         }
            //         if (minStatus) {
            //             if (this.intervalTime[i] !== ':') {
            //                 min = min + this.intervalTime[i]
            //             }

            //         } else {
            //             hour = hour + this.intervalTime[i]
            //         }
            //     }
            // }
            let today = new Date();

            const newDate = new Date(`${today.getFullYear()}, ${today.getMonth()}, ${today.getDate()}, ${this.intervalTime}:00 GMT`);

            this.interval_time_Hour = newDate.getUTCHours();
            this.interval_time_Minute = newDate.getUTCMinutes();

            this.skills = technician.skills ? technician.skills : '';

            this.technicianStatus = technician.status ? true : false;

            this.teamLead.id = technician.team_lead && technician.team_lead.id ? technician.team_lead.id : '';
            this.teamLead.status = technician.team_lead && technician.team_lead.status ? technician.team_lead.status : '';

            const index = this.teamLeads.findIndex((item) => item.value == this.teamLead.id);
            if (index !== -1) {
                this.selectedTeamLead = this.teamLeads[index]
            }

            this.$refs.skillRef.setHTML(this.skills);
        },
        userId(id) {
            this.user_id = id;
        },
        employeeId(id) {
            this.employee_id = id;
        }

    },
    methods: {
        ...mapActions({

            putTechnician: 'appTechnicians/putTechnician',

            postOnSiteTechnician: 'appOnSiteTechnicians/postOnSiteTechnician',
            putOnSiteTechnician: 'appOnSiteTechnicians/putOnSiteTechnician',

            postRemoteTechnician: 'appRemoteTechnicians/postRemoteTechnician',
            putRemoteTechnician: 'appRemoteTechnicians/putRemoteTechnician',

        }),

        async updateOtherInformation() {
            await this.updateTechnician();
            // if (isTechUpdate) {
            //     if (this.onSiteTechnician.id) {
            //         const isOnSiteTechUpdated = await this.updateOnSiteTech();
            //         if (isOnSiteTechUpdated) {

            //             if (this.onSiteTechnician.id) {
            //                 await this.updateRemoteTech();
            //             } else {
            //                 await this.createRemoteTech()
            //             }

            //         }

            //     } else {
            //         const isOnSiteTechCreated = await this.createOnSiteTech();
            //         if (isOnSiteTechCreated) {

            //             if (this.onSiteTechnician.id) {
            //                 await this.updateRemoteTech();
            //             } else {
            //                 await this.createRemoteTech()
            //             }
            //         }
            //     }
            // }
        },

        async updateTechnician() {
            this.loader(true);
            let hour = '';
            let min = '';

            if (`${this.interval_time_Hour}`.length == 1) {
                console.log('object');
                hour = `0${this.interval_time_Hour}`
            } else {
                hour = this.interval_time_Hour;
            }
            if (`${this.interval_time_Minute}`.length == 1) {
                min = `0${this.interval_time_Minute}`
            } else {
                min = this.interval_time_Minute;
            }
            const dataObj = {
                id: this.id,
                data: {
                    team_lead_id: this.selectedTeamLead.value,
                    interval_time: `${hour}:${min}`,
                    skills: this.skills,
                    status: this.technicianStatus ? 1 : 0,
                },
            };
            this.loader(true);
            const response = await this.putTechnician(dataObj);
            this.loader(false);
            if (response.status === 200 || response.status === 201) {
                document.querySelector('[data-target="#TechnicianOtherInformationUpdate"]').click();

                this.$emit('getSingleTechnician', this.$route.params.id);

                this.errors = {};
                this.showToastMessage(response.message);

            } else {
                this.errors = response.errors;

            }
            if (response.message) {
                this.showToastMessage(response);
            }

        },

        async createOnSiteTech() {
            const postOnSiteTechnicianData = {
                user_id: this.user_id,
                employee_id: this.employee_id,
                technician_id: this.id,
                status: this.onSiteTechnicianStatus ? 1 : 0,
            };
            const response = await this.postOnSiteTechnician(postOnSiteTechnicianData);
            if (response.status === 200 || response.status === 201) {
                // document.querySelector('[data-target="#TechnicianProductCommissionUpdate"]').click();

                // this.$emit('getSingleTechnician', this.$route.params.id);

                this.errors = {};
                this.showToastMessage(response.message);
                return true;
            } else {
                this.errors = response.errors;

            }
            if (response.message) {
                this.showToastMessage(response);
            }
            return false;

        },
        async createRemoteTech() {
            const postRemoteTechnicianData = {
                user_id: this.user_id,
                employee_id: this.employee_id,
                technician_id: this.id,
                status: this.remoteTechnicianStatus ? 1 : 0,
            };
            const response = await this.postOnSiteTechnician(postRemoteTechnicianData);
            if (response.status === 200 || response.status === 201) {
                document.querySelector('[data-target="#TechnicianOtherInformationUpdate"]').click();

                this.$emit('getSingleTechnician', this.$route.params.id);

                this.errors = {};
                this.showToastMessage(response.message);
                return true;
            } else {
                this.errors = response.errors;

            }
            if (response.message) {
                this.showToastMessage(response);
            }
            return false;

        },

        async updateOnSiteTech() {
            const dataObj = {
                id: this.onSiteTechnician.id,
                data: {
                    status: this.onSiteTechnicianStatus === true ? 1 : 0,
                },
            };
            const response = await this.putOnSiteTechnician(dataObj);
            if (response.status === 200 || response.status === 201) {
                // document.querySelector('[data-target="#TechnicianProductCommissionUpdate"]').click();

                // this.$emit('getSingleTechnician', this.$route.params.id);

                this.errors = {};
                this.showToastMessage(response.message);
                return true;
            } else {
                this.errors = response.errors;

            }
            if (response.message) {
                this.showToastMessage(response);
            }
            return false;

        },

        async updateRemoteTech() {
            const dataObj = {

                id: this.remoteTechnician.id,
                data: {
                    status: this.remoteTechnicianStatus ? 1 : 0,
                },
            };
            const response = await this.putRemoteTechnician(dataObj);
            if (response.status === 200 || response.status === 201) {
                document.querySelector('[data-target="#TechnicianOtherInformationUpdate"]').click();

                this.$emit('getSingleTechnician', this.$route.params.id);

                this.errors = {};
                this.showToastMessage(response.message);
                return true;
            } else {
                this.errors = response.errors;

            }
            if (response.message) {
                this.showToastMessage(response);
            }
            return false;

        }

    },
    computed: {
        teamLeads() {
            let activeTeamLeads = this.$store.getters['appTeamLeads/teamLeads'].map((teamLeadObj) => {
                let teamLeadId = teamLeadObj.id;

                let firstName = teamLeadObj.user && teamLeadObj.user.first_name ? teamLeadObj.user.first_name : '';
                let lastName = teamLeadObj.user && teamLeadObj.user.last_name ? teamLeadObj.user.last_name : '';
                let fullName = `${firstName} ${lastName}`;

                return {
                    value: teamLeadId,
                    name: fullName
                };
            });

            return [{
                value: '',
                name: `No One`
            }, ...activeTeamLeads];
        },
    },

}
</script>

<style>

</style>
