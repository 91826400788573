<template>
<div class="modal fade " id="OnSiteTechnicianUpdate" tabindex="-1" role="dialog" aria-labelledby="OnSiteTechnicianUpdate" aria-hidden="true">
    <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title white">Other information's</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">

                <div class="row">

                    <div class="col-12">
                        <div class="form-group">
                            <div class="controls">
                                <label>On-Site Technician Status</label>
                                <div class="" style="margin-top: .5rem">
                                    <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                                        <div>
                                            <input v-model="onSiteTechnicianStatus" type="checkbox" class="custom-control-input" checked id="onSiteTechnicianStatusCheckbox">
                                            <label class="custom-control-label mr-1" for="onSiteTechnicianStatusCheckbox"></label>
                                        </div>
                                        <span class="font-medium-1">{{onSiteTechnicianStatus ? "Active" : "Inactive"}}</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

            <div class="modal-footer border-0 pt-0">
                <button type="button" class="btn btn-primary" @click="updateOnSiteInformation">
                    <i class="bx bx-x d-block d-sm-none"></i>
                    <span class="d-none d-sm-block">Save Changes</span>
                </button>

            </div>
        </div>
    </div>
</div>
</template>

<script>
import {

    mapActions
} from 'vuex';
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";

export default {
    mixins: [ShowToastMessage,Loader],
    name: "OnSiteTechnicianUpdate",
    props: ['currentTechnician', 'userId', 'employeeId'],
    components: {

    },
    data() {
        return {
            user_id: '',
            employee_id: '',
            errors: {},
            id: '',

            onSiteTechnician: {
                id: '',
                status: '',
            },

            onSiteTechnicianStatus: '',

        }
    },
    watch: {
        currentTechnician(technician) {
            this.id = technician.id ? technician.id : '';

            this.onSiteTechnician.id = technician.onSiteTechnician && technician.onSiteTechnician.id ? technician.onSiteTechnician.id : '';
            this.onSiteTechnician.status = technician.onSiteTechnician && technician.onSiteTechnician.status ? technician.onSiteTechnician.status : '';
            this.onSiteTechnicianStatus = technician.onSiteTechnician && technician.onSiteTechnician.status !== 'Inactive' ? true : false;
        },
        userId(id) {
            this.user_id = id;
        },
        employeeId(id) {
            this.employee_id = id;
        }

    },
    methods: {
        ...mapActions({

            postOnSiteTechnician: 'appOnSiteTechnicians/postOnSiteTechnician',
            putOnSiteTechnician: 'appOnSiteTechnicians/putOnSiteTechnician',

        }),

        async updateOnSiteInformation() {

            if (this.onSiteTechnician.id) {
                await this.updateOnSiteTech();

            } else {

                await this.createOnSiteTech();
            }

        },
        async createOnSiteTech() {
            this.loader(true);
            const postOnSiteTechnicianData = {
                user_id: this.user_id,
                employee_id: this.employee_id,
                technician_id: this.id,
                status: this.onSiteTechnicianStatus ? 1 : 0,
            };
            const response = await this.postOnSiteTechnician(postOnSiteTechnicianData);
            this.loader(false);
            if (response.status === 200 || response.status === 201) {
                document.querySelector('[data-target="#OnSiteTechnicianUpdate"]').click();

                this.$emit('getSingleTechnician', this.$route.params.id);

                this.errors = {};
                this.showToastMessage(response.message);
                return true;
            } else {
                this.errors = response.errors;

            }
            if (response.message) {
                this.showToastMessage(response);
            }
            return false;

        },
        async updateOnSiteTech() {
            this.loader(true);
            const dataObj = {
                id: this.onSiteTechnician.id,
                data: {
                    status: this.onSiteTechnicianStatus === true ? 1 : 0,
                },
            };
            const response = await this.putOnSiteTechnician(dataObj);
            this.loader(false);
            if (response.status === 200 || response.status === 201) {
                document.querySelector('[data-target="#OnSiteTechnicianUpdate"]').click();

                this.$emit('getSingleTechnician', this.$route.params.id);

                this.errors = {};
                this.showToastMessage(response.message);
                return true;
            } else {
                this.errors = response.errors;

            }
            if (response.message) {
                this.showToastMessage(response);
            }
            return false;

        },

    },
    computed: {

    },

}
</script>

<style>

</style>
