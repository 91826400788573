<template>
<div class="modal fade " id="TechnicianUserUpdateModal" tabindex="-1" role="dialog" aria-labelledby="TechnicianUserUpdateModal" aria-hidden="true">
    <div class="modal-dialog  modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title white">Update User Information</h1>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    <div class="row ">
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <div class="">
                                    <label>First name</label>
                                    <input v-model="putUserData.first_name" type="text" class="form-control " placeholder="Enter first name" />
                                    <div v-if="errors.first_name !== undefined && errors.first_name.length > 0">
                                        <p v-for="(error,index) in  errors.first_name" :key="index" class="text-danger mb-1">
                                            {{ error }}
                                        </p>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <div class="">
                                    <label>Last name</label>
                                    <input v-model="putUserData.last_name" type="text" class="form-control " placeholder="Enter last name" />
                                    <div v-if="errors.last_name !== undefined && errors.last_name.length > 0">
                                        <p v-for="(error,index) in  errors.last_name" :key="index" class="text-danger mb-1">
                                            {{ error }}
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <div class="">
                                    <label>Phone number</label>
                                    <input v-model="putUserData.phone_number" type="tel" class="form-control " placeholder="ie. 0412345678" />
                                    <div v-if="errors.phone_number !== undefined && errors.phone_number.length > 0">
                                        <p v-for="(error,index) in  errors.phone_number" :key="index" class="text-danger mb-1">
                                            {{ error }}
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <div class="">
                                    <label>Email address</label>
                                    <input v-model="putUserData.email" type="email" class="form-control " placeholder="ie. example@gmail.com" />
                                   <div v-if="errors.email !== undefined && errors.email.length > 0">
                                        <p v-for="(error,index) in  errors.email" :key="index" class="text-danger mb-1">
                                            {{ error }}
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group">
                                <label>Date of Birth</label>
                                <div class="d-flex align-items-center date-picker-wrapper">
                                    <div class="d-flex align-items-center">
                                        <i class='bx bx-calendar'></i>
                                    </div>
                                    <div class="w-100 pl-1 ">
                                        <div class="w-100 ">
                                            <DatePicker v-model="putUserData.date_of_birth" :update-on-input="true" :masks="{input: ['DD MMMM YYYY']}" :model-config="{ type: 'string', mask: 'YYYY-MM-DD'}">
                                                <template v-slot="{ inputValue, inputEvents }">
                                                    <input class="form-control" :value="inputValue" v-on="inputEvents" />
                                                </template>
                                            </DatePicker>
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="modal-footer border-0 pt-0">
                <button type="button" class="btn btn-primary" @click="updateUserInformation">
                    <i class="bx bx-x d-block d-sm-none"></i>
                    <span class="d-none d-sm-block">Save Changes</span>
                </button>

            </div>
        </div>
    </div>
</div>
</template>

<script>
import {

    mapActions
} from 'vuex';
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
// import VueMultiselect from 'vue-multiselect';
import {
    DatePicker
} from 'v-calendar';
export default {
    mixins: [ShowToastMessage],
    name: "TechnicianUserUpdateModal",
    props: ['user','addressId'],
    components: {

        DatePicker,

        // VueMultiselect,
    },
    data() {
        return {
            errors: {},
            userId: '',
            putUserData: {
                first_name: '',
                last_name: '',
                email: '',
                phone_number: '',
                date_of_birth: '',
                address_id: '',

            },

        }
    },
    watch: {
        user(currentUser) {
            this.putUserData.first_name = currentUser.firstName;
            this.putUserData.last_name = currentUser.lastName;
            this.putUserData.email = currentUser.email;
            this.putUserData.phone_number = currentUser.phoneNumber;
            this.putUserData.date_of_birth = currentUser.dateOfBirth;
          
            this.userId = currentUser.id;
            console.log(currentUser);
        },
        addressId(addressId){
              this.putUserData.address_id = addressId;
        }

    },
    methods: {
        ...mapActions({
            putService: 'appService/putService',

            putUser: 'appUsers/putUser',

        }),

        async updateUserInformation() {
            const dataObj = {
                id: this.userId,
                data: this.putUserData,
            };
            const response = await this.putUser(dataObj);
            console.log(response);

            if (response.status === 200 || response.status === 201) {
                document.querySelector('[data-target="#TechnicianUserUpdateModal"]').click();

                this.$emit('getSingleTechnician', this.$route.params.id);

                this.errors = {};
                this.showToastMessage(response.message);
            } else {
                this.errors = response.errors;
            }
            if (response.message) {
                this.showToastMessage(response);
            }

        }

    },

}
</script>

<style>

</style>
